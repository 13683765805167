<template>
  <v-card
      elevation="0"
      class="table-container"
  >

    <div
        class="d-flex align-center justify-center pa-16"
        v-if="table.loading">
      <v-progress-circular
          indeterminate
          color="main"
      />
    </div>
    <div v-if="table.items.length !== 0  && !table.loading">
      <slot/>
    </div>
    <div
        v-if="table.items.length ===0  && !table.loading"
        class="d-flex align-center justify-center flex-column w-100 my-8">

      <v-icon size="100">
        mdi-folder-information-outline
      </v-icon>
      <h3 class="mb-4">
        موردی برای نمایش وجود ندارد.
      </h3>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "DesktopTable",
  props: {
    table: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>

<style>
.table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #000 !important;
}

.table-body td {
  font-size: 13px !important;
}
</style>